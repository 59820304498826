import * as React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/link-resolver"
// import { ProductListing } from "../components/product-listing"
import Layout from "../components/layout"
import SliceZone from "../components/slice-zone"

// import { JsonDebugger } from "../components/json-debugger"

const HomeTemplate = ({ data }) => {
  const { prismicHomepage: { data: pageData = {} } = {} } = data
  return (
    <Layout id={"homepage"}>
      <SliceZone sliceZone={pageData.body} padded={true} />
      {/* <JsonDebugger json={data} /> */}
    </Layout>
  )
}

export const query = graphql`
  query Homepage {
    prismicHomepage {
      _previewable
      data {
        # banner_title {
        #   richText
        # }
        # banner_description {
        #   richText
        # }
        # banner_link {
        #   url
        #   type
        #   uid
        # }
        # banner_link_label {
        #   richText
        # }
        # banner_background {
        #   url
        # }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyHero
          ...HomepageDataBodyText
          ...HomepageDataBodyQuote
          ...HomepageDataBodyFullWidthImage
          ...HomepageDataBodyImageGallery
          ...HomepageDataBodyImageHighlight
          ...HomepageDataBodyCardsCarousel
          ...HomepageDataBodyTestimonialCarousel
          ...HomepageDataBodyFeaturedProducts
          ...HomepageDataBodyVideoHighlights
        }
      }
    }
  }
`

export default HomeTemplate
// export default withPrismicPreview(HomeTemplate, [
//   {
//     repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
//     linkResolver,
//   },
// ])
